<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-province class="mr-2" v-model:value="provinsi" />
        <filter-distributor class="mr-2 mt-2" v-model:value="distributor" v-model:provinsi="provinsi"></filter-distributor>
        <filter-brands v-show="!isRtmNonSemen" class="mr-2 mt-2" v-model:value="brand" v-model:brand="brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          class="mt-2 mr-2"
          style="width: 300px;"
          v-model:value="start_date"
          :disabled-date="disabledStartDate"
          placeholder="Dari Tanggal"
          format="DD MMMM YYYY"
        />
        <a-date-picker
          style="width: 300px;"
          class="mt-2 mr-2"
          v-model:value="end_date"
          :disabled-date="disabledEndDate"
          placeholder="Sampai Tanggal"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mt-2 mr-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button
          class="mt-2 mr-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <a-button
          class="mt-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <md-table
                :columns="columns"
                :data-source="data"
                :row-selection="rowSelection"
                size="small"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                bordered
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #customer="{ text }">
                  <span v-if="searchText">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark
                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                  </template>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <span
                    :class="[
                      text === 'Aktif'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-default',
                    ]"
                    >{{ text }}</span
                  >
                </template>
                <template #margin="{ text }">
                  <span v-if="parseInt(text) >= 100" class="text-custom-red">
                    {{ text ? Math.round(text) + '%' : 0 }}
                  </span>
                  <span v-else-if="parseInt(text) < 100 && parseInt(text) >= 80" class="text-custom-orange">
                    {{ text ? Math.round(text) + '%' : 0 }}
                  </span>
                  <span v-else class="text-custom-dark">
                    {{ text ? Math.round(text) + '%' : 0 }}
                  </span>
                </template>
                <template #action>
                  <span>
                    <a-tooltip title="Lihat Pengguna">
                      <a href="javascript: void(0);" class="btn btn-sm btn-light">
                        <i class="fe fe-eye" />
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Ubah Pengguna">
                      <a href="javascript: void(0);" class="btn btn-sm btn-light">
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Detail Regional dan Area">
                      <a href="javascript: void(0);" class="btn btn-sm btn-light">
                        <small>
                          <i class="fe fe-globe" />
                        </small>
                      </a>
                    </a-tooltip>
                  </span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import adata from './data.json'
import apiClient from '@/services/axios'
// import MModal from './modal'
// import FilterRegional from '@/components/filter/FilterRegional'
// import FilterPropinsi from '@/components/filter/FilterPropinsi'
import FilterProvince from '@/components/filter/FilterProvince'
// import FilterArea from '@/components/filter/FilterArea'
// import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributorOI'
import FilterBrands from '@/components/filter/FilterBrand'
// import FilterProducts from '@/components/filter/FilterProduct'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  // {
  //   title: 'Bulan',
  //   dataIndex: 'bulan',
  //    width: 100,
  // },
  {
    title: 'Kode Distributor',
    dataIndex: 'dist_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'dist_name',
    // width: 200,
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
    // width: 130,
  },
  {
    title: 'Nama Produk',
    dataIndex: 'product_name',
    // width: 200,
  },
  // {
  //   title: 'Region Gudang',
  //   dataIndex: 'region_name',
  //    width: 100,
  // },
  // {
  //   title: 'Area Gudang',
  //   dataIndex: 'area_name',
  // },
  // {
  //   title: 'Kota / Kabupaten Gudang',
  //   dataIndex: 'kab_name',
  //    width: 180,
  // },
  {
    title: 'Harga Beli ',
    dataIndex: 'price',
  },
  {
    title: 'Harga Jual (MIN) ',
    dataIndex: 'min_price',
    // width: 100,
  },
  {
    title: 'Harga Jual (MAX) ',
    dataIndex: 'max_price',
    // width: 100,
  },
  {
    title: 'Harga Jual (AVG) ',
    dataIndex: 'avg_price',
  },
  {
    title: 'Margin',
    dataIndex: 'margin',
  },
  {
    title: '% Margin ',
    dataIndex: 'margin_persen',
    // slots: { customRender: 'margin' },
  },
]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default defineComponent({
  components: {
    // FilterRegional,
    // FilterPropinsi,
    FilterProvince,
    // FilterArea,
    // FilterKabupatenKota,
    FilterDistributor,
    // FilterProducts,
    FilterBrands,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const q = ref('')
    const provinsi = ref([])
    const current1 = ref(1)
    const brand = ref([])
    const distributor = ref([])
    const dataPropinsi = ref([])
    const errorMessage = ref(null)
    const { isRtmNonSemen } = useUserInfo()

    const {
      startDate,
      endDate,
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()

    columns.value = acolumns

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        // region: regionals.value,
        // area: areas.value,
        provinsi: provinsi.value,
        // kabupaten: kabupaten.value,
        // produk: products.value,
        brand: brand.value,
        distributor: distributor.value,
        start_date: startDate.value,
        end_date: endDate.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/report/gpm-distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/gpm-distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `gpm-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const fetchDataPropinsi = propinsi => {
      apiClient.get('/api/filter/wilayah/1', { params: { q: propinsi } }).then(response => {
        dataPropinsi.value = response.data
      })
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    return {
      q,
      searchText,
      searchInput,
      data,
      isRtmNonSemen,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      brand,
      provinsi,
      distributor,
      onSelectChange,
      state,
      fetchXlsx,
      // distributors,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      fetchData,
      dataPropinsi,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.text-custom-red {
  color: #ff0000;
}

.text-custom-orange {
  color: #ffa500;
}

.text-custom-yellow {
  color: #eedf11;
}

.text-custom-dark {
  color: #000000;
}

.text-custom-green {
  color: #008000;
}
</style>
