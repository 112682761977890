<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Distributor"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="blur"
    @select="select"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in dataRegional" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'
export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
    provinsi: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const dataRegional = ref([])
    const findText = ref(null)
    const { provinsi, value } = toRefs(props)
    const loading = ref(false)

    const fetchDataRegional = debounce((provinsi, q = null) => {
      if (provinsi.length <= 0) provinsi = null
      loading.value = true
      apiClient
        .get('/api/filter/distributor-by-role', {
          params: { provinsi: provinsi, q },
        })
        .then(response => {
          const sorted = response.data
          dataRegional.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .catch(err => console.log(err))
        .finally(() => {
          loading.value = false
        })
    }, 500)

    onMounted(() => {
      fetchDataRegional(provinsi.value)
    })

    watch(provinsi, (after, before) => {
      emit('update:value', [])
      // console.log('propinsi')
      fetchDataRegional(after)
    })

    watch(value, (after, before) => {
      if (after === null || after === undefined || after === []) fetchDataRegional(provinsi.value)
    })

    const onSearch = debounce(value => {
      loading.value = true
      console.log({ value, findText: findText.value })
      findText.value = value
      fetchDataRegional(provinsi.value, value)
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }
    const select = value => {
      if (findText.value !== null) {
        fetchDataRegional(provinsi.value)
        findText.value = null
      }
    }
    const blur = value => {
      console.log({ blur: '', value })
    }

    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchDataRegional(provinsi.value)
        findText.value = null
      }
    }

    return {
      dataRegional,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      blur,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
